
























import { Component, Vue } from 'vue-property-decorator';
import SessionPanel from './SessionPanel.vue';
import SessionConfigurationStepper, { StepNumber } from '@/components/session/configuration/SessionConfigurationStepper.vue';
import APP_CONST from '@/constants/AppConst';
import { Location } from 'vue-router';

@Component({
  components: {
    SessionPanel,
    SessionConfigurationStepper,
  }
})
export default class SessionConfiguration extends Vue {
  activeStep = StepNumber.Session;;

  get programSessionsRouteTo(): Location {
    return {
      name: APP_CONST.APP_ROUTES.ACCOUNT_PROGRAMS_SESSIONS.name,
    };
  }
}
